import React from 'react';

const Travel = () => (
  <main>
    <section>
      <h2 style={{ marginTop: '5rem', textAlign: 'center' }}>
        The Travel page is Under Construction
      </h2>
    </section>
  </main>
);

export default Travel;
