import React from 'react';

const Travels = () => (
  <main>
    <section>
      <h2 style={{ marginTop: '5rem', textAlign: 'center' }}>
        The Travels page is Under Construction
      </h2>
    </section>
  </main>
);

export default Travels;
