import React from 'react';

const Contacts = () => (
  <main>
    <section>
      <h2 style={{ marginTop: '5rem', textAlign: 'center' }}>
        The Contacts page is Under Construction
      </h2>
    </section>
  </main>
);
export default Contacts;
